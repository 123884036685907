import { useCallback, useState } from 'react';
import { MaxWidth } from '@klokgroep/shared-components/src/MaxWidth';
import { NewsletterAdditionalDataForm } from '@klokgroep/shared-components/src/Newsletter';
import { BlockContainerWithBackground } from '@klokgroep/shared-components/src/BlockContainerWithBackground';
import styles from './BlockNewsletterSignUp.module.css';
import { LinkWithChevron } from '@klokgroep/shared-components/src/LinkWithChevron';
import { useCommonTranslations } from '@klokgroep/shared-components/utils/useTranslations';

interface Properties {
  title?: string;
}

export const BlockNewsletterSignUp = ({ title }: Properties) => {
  const [success, setSuccess] = useState(false);

  const onSuccess = useCallback(() => setSuccess(true), []);

  const t = useCommonTranslations();

  return (
    <div className={styles.container}>
      <BlockContainerWithBackground smallPaddingBottom backgroundColor="lightGray">
        <MaxWidth size="small">
          <h2 className={styles.title}>{title}</h2>
          {success ? (
            <p>
              {t('newsletter_block_success_label')}
              <LinkWithChevron label={t('newsletter_block_success_link_label')} href="/" />
            </p>
          ) : (
            <NewsletterAdditionalDataForm onSuccess={onSuccess} />
          )}
        </MaxWidth>
      </BlockContainerWithBackground>
    </div>
  );
};
