import React, { CSSProperties, useMemo } from 'react';
import styles from './ContentCardBoxedLarge.module.css';
import { SourceSetSanityImage } from '@klokgroep/shared-components/src/SourceSet';
import cx from 'classnames';
import { MaxWidth } from '@klokgroep/shared-components/src/MaxWidth';

const SIZES = [
  { width: 1400, minWidth: 1300 },
  { width: 1200, minWidth: 1100 },
  { width: 1000, minWidth: 900 },
  { width: 800, minWidth: 700 },
  { width: 600, minWidth: 500 },
  { width: 400, minWidth: 300 },
  { width: 200, minWidth: 1 },
];

type Mapped<N extends number, Result extends Array<unknown> = []> = Result['length'] extends N
  ? Result
  : Mapped<N, [...Result, Result['length']]>;

interface Properties {
  image?: Sanity.Image;
  children?: React.ReactNode;
  alignContent?: 'left' | 'right';
  isFullWidth?: boolean;
  smallGap?: boolean;
  contentFullWidth?: boolean;
  gradientHardness?: Mapped<100>[number];
  gradientDegree?: Mapped<360>[number];
}

// This component displays content on top of an image.
export const ContentCardBoxedLarge = ({
  image,
  children,
  alignContent,
  isFullWidth,
  smallGap,
  contentFullWidth,
  gradientHardness,
  gradientDegree,
}: Properties) => {
  const style = useMemo(
    () =>
      ({
        '--gradient-hardness': gradientHardness ? `${gradientHardness}%` : undefined,
        '--gradient-degree': gradientDegree ? `${gradientDegree}deg` : undefined,
        //We have to cast this, as --gradient(...) is not a valid CSS property.
      } as CSSProperties),
    [gradientHardness, gradientDegree]
  );
  return (
    <div className={cx(styles.container, alignContent === 'right' ? styles.alignRight : undefined)} style={style}>
      {contentFullWidth ? (
        <div
          className={cx(styles.contentHolder, {
            [styles.smallGap]: smallGap,
            [styles.contentFullWidth]: true,
          })}>
          {children}
        </div>
      ) : isFullWidth ? (
        <MaxWidth>
          <div className={styles.contentHolder}>{children}</div>
        </MaxWidth>
      ) : (
        <div className={cx(styles.contentHolder, { [styles.smallGap]: smallGap })}>{children}</div>
      )}
      <div className={cx(styles.imageHolder, isFullWidth ? undefined : styles.hasRadius)}>
        {image ? (
          <SourceSetSanityImage
            asset={image}
            loading="lazy"
            withoutHotspot={isFullWidth}
            sizes={isFullWidth ? [{ width: 2000, minWidth: 1400 }, ...SIZES] : SIZES}
          />
        ) : undefined}
      </div>
    </div>
  );
};
