import { Chevron } from '@klokgroep/shared-components/src/Icons';
import React, { useMemo, useState } from 'react';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import cx from 'classnames';
import styles from './FoldOutsWithLogos.module.css';
import { PortableTextWithLinks } from '@klokgroep/shared-components/src/PortableTextWithLinks';
import { SanityRichTextType } from '@klokgroep/sanity';

const KlokGroep = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 0.800049H0V5.65577H24V0.800049Z" />
    <path d="M12 13.8547L24 23.1283V16.999L12 7.72534L0 16.999V23.1283L12 13.8547Z" />
  </svg>
);

const NovaForm = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 0.800049H0V5.65577H24V0.800049Z" />
    <path d="M12 13.8547L24 23.1283V16.999L12 7.72534L0 16.999V23.1283L12 13.8547Z" />
  </svg>
);

const Heilijgers = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 0.800049H0V5.65577H24V0.800049Z" />
    <path d="M12 13.8547L24 23.1283V16.999L12 7.72534L0 16.999V23.1283L12 13.8547Z" />
  </svg>
);

const VanErk = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 0.800049H0V5.65577H24V0.800049Z" />
    <path d="M12 13.8547L24 23.1283V16.999L12 7.72534L0 16.999V23.1283L12 13.8547Z" />
  </svg>
);

const ICONS_AND_HOVER_COLORS = {
  none: undefined,
  VanDeKlok: { Icon: KlokGroep, hoverColor: '#E58937' },
  NovaForm: { Icon: NovaForm, hoverColor: '#C4332A' },
  Heilijgers: { Icon: Heilijgers, hoverColor: '#44934D' },
  VanErk: { Icon: VanErk, hoverColor: '#4799D7' },
};

export interface FoldOutsWithLogosProperties {
  alwaysOpen?: boolean;
  items:
    | null
    | {
        alwaysOpen?: boolean;
        title?: string;
        content?: SanityRichTextType;
        icon?: keyof typeof ICONS_AND_HOVER_COLORS;
      }[];
  inverted?: boolean;
}

export const FoldOutsWithLogos = ({ alwaysOpen, items = [], inverted }: FoldOutsWithLogosProperties) => (
  <div className={inverted ? styles.inverted : undefined}>
    {items?.map(({ title, content, icon }) => (
      <FoldOutItem key={title} alwaysOpen={alwaysOpen} title={title} content={content} icon={icon} />
    ))}
  </div>
);

const FoldOutItem = ({
  alwaysOpen = false,
  content,
  icon = 'VanDeKlok',
  title,
}: NonNullable<FoldOutsWithLogosProperties['items']>[0]) => {
  const [isOpen, setIsOpen] = useState(alwaysOpen);
  const elementToUse = ICONS_AND_HOVER_COLORS[icon] || ICONS_AND_HOVER_COLORS['VanDeKlok'];

  const iconStyle = useMemo(
    () => (icon === 'none' ? { display: 'none' } : { ['--hover-color']: elementToUse?.hoverColor }),
    [elementToUse?.hoverColor, icon]
  );

  return (
    <div className={styles.item}>
      <button
        className={styles.button}
        type="button"
        onClick={alwaysOpen ? undefined : () => setIsOpen((isOpen) => !isOpen)}>
        <span className={cx(styles.buttonInner, { [styles.isOpen]: isOpen })}>
          <span className={styles.logoTitleContainer}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <span className={styles.logo} style={iconStyle}>
              {elementToUse ? <elementToUse.Icon /> : undefined}
            </span>
            <span>{title}</span>
          </span>
          {alwaysOpen ? undefined : <Chevron />}
        </span>
      </button>
      <div className={cx(styles.content, { [styles.isOpen]: isOpen, [styles.noLeftPadding]: icon === 'none' })}>
        <RichText>{content ? <PortableTextWithLinks content={content} /> : undefined}</RichText>
      </div>
    </div>
  );
};
