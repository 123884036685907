import { ButtonLink } from '@klokgroep/shared-components/src/Button';
import { ButtonLinkProperties } from '@klokgroep/shared-components/src/Button/ButtonInterfaces';
import { LinkType, createHref } from '@klokgroep/sanity';
import { LinkWithChevron } from '@klokgroep/shared-components/src/LinkWithChevron';

interface ButtonProperties extends LinkType {
  externalLink?: string;
  href?: { _type: Sanity.Schema.Document['_type']; slug?: { _type: 'slug'; current?: string } };
  label?: string;
  targetBlank?: boolean;
  type?: 'external' | 'reference';
}

export const renderButtonOrLink = (
  button: ButtonProperties,
  renderButton: boolean,
  buttonLinkTheme?: ButtonLinkProperties['theme']
) => {
  if (renderButton) {
    return (
      <ButtonLink href={createHref(button)} target={button.targetBlank ? 'blank' : undefined} theme={buttonLinkTheme}>
        {button.label}
      </ButtonLink>
    );
  }
  return (
    <LinkWithChevron
      label={button.label}
      href={createHref(button)}
      target={button.targetBlank ? 'blank' : undefined}
      hasSpacing={false}
    />
  );
};
