import { ReactNode } from 'react';
import styles from './PentagonContainer.module.css';

interface Properties {
  children: ReactNode;
}

export const PentagonContainer = ({ children }: Properties) => (
  <div className={styles.container}>
    <PentagonBackground />
    <div className={styles.children}>{children}</div>
  </div>
);

// This should match the mask in css
const PentagonBackground = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 592 592">
    <path
      fill="#75EEA9"
      d="M298.661 572.411c-4.116 3.022-10.788 3.022-14.912 0L10.566 372.107c.353.356.744.72 1.213 1.085L282.774 589.21c4.661 3.712 12.209 3.712 16.87 0l270.703-215.918c.261-.209.499-.41.722-.62L298.661 572.403v.008Z"
    />
    <path
      fill="#6CBAE4"
      d="M106.945 23.278c1.574-4.882 6.972-8.842 12.063-8.842h334.726L109.579.008c-5.935-.248-12.032 4.23-13.63 9.996L3.48 344.14a14.045 14.045 0 0 0-.33 1.542L106.944 23.278Z"
    />
    <path
      fill="#002E5C"
      d="m579.576 346.675 6.719-4.82c4.837-3.472 7.026-10.818 4.883-16.405L478.228 30.988c-.33-.86-.66-1.565-.982-2.146l102.33 317.84v-.007Z"
    />
    <path
      fill="#000"
      d="M283.749 572.411c4.116 3.022 10.788 3.022 14.912 0l278.612-204.287c4.115-3.022 6.181-9.423 4.607-14.313L475.457 23.278c-1.574-4.882-6.972-8.842-12.063-8.842H119.008c-5.091 0-10.489 3.96-12.063 8.842L.53 353.819c-1.574 4.882.491 11.29 4.607 14.313l278.612 204.286v-.007Z"
    />
  </svg>
);
