import { BlockContainerWithBackground } from '@klokgroep/shared-components/src/BlockContainerWithBackground';
import { MaxWidth } from '@klokgroep/shared-components/src/MaxWidth';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import React from 'react';
import { TextWithContentCardsBlock } from '@klokgroep/sanity/src/corporateQueries/blocks/textWithContentCards';
import { PortableTextWithLinks } from '@klokgroep/shared-components/src/PortableTextWithLinks';
import { ContentCardBoxed } from '@klokgroep/shared-components/src/ContentCardBoxed';
import { ContentCard } from '@klokgroep/shared-components/src/ContentCard';
import styles from './BlockTextWithContentCards.module.css';
import { TitleOverTwoRules } from '@klokgroep/shared-components/src/TitleOverTwoRules';
import classNames from 'classnames';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';
import { renderButtonOrLink } from '@klokgroep/shared-components/utils/renderButtonOrLink';

export const BlockTextWithContentCards = ({
  title,
  text,
  isBoxed = false,
  contentCards,
  settings,
  button,
}: TextWithContentCardsBlock) => {
  const { gridColumns = 3, backgroundColor, imageCrop = 'landscape' } = settings || {};
  const { locale, theme } = useSiteInfo();

  return (
    <BlockContainerWithBackground backgroundColor={backgroundColor}>
      <div className={styles.container}>
        {title || text ? (
          <MaxWidth>
            <div className={styles.textContainer}>
              <RichText>
                {title ? <TitleOverTwoRules as="h2">{title}</TitleOverTwoRules> : undefined}
                {text ? <PortableTextWithLinks content={text} /> : undefined}
                {button && button.label ? renderButtonOrLink({ ...button, locale }, theme !== 'novaform') : undefined}
              </RichText>
            </div>
          </MaxWidth>
        ) : undefined}
        <MaxWidth>
          {contentCards && contentCards.length > 0 ? (
            <div className={classNames(styles.grid, styles['cols' + gridColumns])}>
              {contentCards.map((contentCard) =>
                isBoxed ? (
                  <ContentCardBoxed
                    key={contentCard._key}
                    title={contentCard.title}
                    subText={contentCard.subText}
                    link={contentCard.link}
                    image={contentCard.image}
                  />
                ) : (
                  <ContentCard
                    key={contentCard._key}
                    title={contentCard.title}
                    subText={contentCard.subText}
                    link={contentCard.link}
                    image={contentCard.image}
                    imageCrop={imageCrop}
                  />
                )
              )}
            </div>
          ) : undefined}
        </MaxWidth>
      </div>
    </BlockContainerWithBackground>
  );
};
