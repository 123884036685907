import { BlockContainerWithBackground } from '@klokgroep/shared-components/src/BlockContainerWithBackground';
import styles from './BlockWithTitleAndMultipleRichText.module.css';
import { MaxWidth } from '@klokgroep/shared-components/src/MaxWidth';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { TitleOverTwoRules } from '@klokgroep/shared-components/src/TitleOverTwoRules';
import React from 'react';
import { PortableTextWithLinks } from '@klokgroep/shared-components/src/PortableTextWithLinks';
import { TitleMultipleRichTextBlock } from '@klokgroep/sanity/src/corporateQueries/blocks/titleMultipleRichText';
import { LinkWithChevron } from '@klokgroep/shared-components/src/LinkWithChevron';

import { createHref } from '@klokgroep/sanity';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';

export const BlockWithTitleAndMultipleRichText = ({
  backgroundColor,
  title,
  subText,
  button,
  richTextModules,
}: TitleMultipleRichTextBlock) => {
  const { locale } = useSiteInfo();
  return (
    <BlockContainerWithBackground backgroundColor={backgroundColor}>
      <MaxWidth>
        <div className={styles.grid}>
          <div className={styles.titleContainer}>
            <RichText>{title ? <TitleOverTwoRules as="h2">{title}</TitleOverTwoRules> : undefined}</RichText>
            {button?.label ? (
              <LinkWithChevron
                label={button?.label}
                href={createHref({ ...button, locale })}
                target={button?.targetBlank ? 'blank' : undefined}
              />
            ) : undefined}
          </div>
          <div className={styles.subTextContainer}>
            <RichText large>{subText ? <PortableTextWithLinks content={subText} /> : undefined}</RichText>
          </div>
          <section className={styles.moduleGrid}>
            {richTextModules?.map((moduleItem) => (
              <div className={styles.module} key={moduleItem._key}>
                <RichText>
                  {moduleItem.content ? <PortableTextWithLinks content={moduleItem.content} /> : undefined}
                </RichText>
              </div>
            ))}
          </section>
        </div>
      </MaxWidth>
    </BlockContainerWithBackground>
  );
};
