import { BlockContainerWithBackground } from '@klokgroep/shared-components/src/BlockContainerWithBackground';
import { ButtonLink } from '@klokgroep/shared-components/src/Button';
import { createHref, LinkType } from '@klokgroep/sanity';
import { PortableTextWithLinks } from '@klokgroep/shared-components/src/PortableTextWithLinks';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { SourceSetSanityImage } from '@klokgroep/shared-components/src/SourceSet';
import { TitleOverTwoRules } from '@klokgroep/shared-components/src/TitleOverTwoRules';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';
import { XlImageWithRichTextAndButtonBlock } from '@klokgroep/sanity/src/queries/blocks/xlImageWithRichTextAndButton';
import cx from 'classnames';
import styles from './BlockWithXlImageRichTextAndButton.module.css';
import { useMemo } from 'react';

interface Properties extends Omit<XlImageWithRichTextAndButtonBlock, 'button'> {
  button?: LinkType;
}

export const BlockWithXlImageRichTextAndButton = ({
  backgroundColor,
  button,
  content,
  image,
  reversed,
  title,
  textAlignment,
}: Properties) => {
  const { locale } = useSiteInfo();
  const textAlignmentClass = useMemo(() => {
    switch (textAlignment) {
      case 'top': {
        return styles.top;
      }
      case 'bottom': {
        return styles.bottom;
      }
      default: {
        return styles.center;
      }
    }
  }, [textAlignment]);
  return (
    <BlockContainerWithBackground backgroundColor={backgroundColor} noDesktopPaddingTopBottom>
      <div className={cx(styles.container, { [styles.reversed]: reversed })}>
        {image ? (
          <div className={styles.imageContainer}>
            <SourceSetSanityImage asset={image} />
          </div>
        ) : undefined}
        {!!title || !!content ? (
          <div className={cx(styles.card, textAlignmentClass)}>
            <RichText>
              {title ? <TitleOverTwoRules as="h2">{title}</TitleOverTwoRules> : undefined}
              {content ? <PortableTextWithLinks content={content} /> : undefined}
            </RichText>
            {button?.label ? (
              <ButtonLink href={createHref({ ...button, locale })} target={button?.targetBlank ? 'blank' : undefined}>
                {button?.label}
              </ButtonLink>
            ) : undefined}
          </div>
        ) : undefined}
        <div />
      </div>
    </BlockContainerWithBackground>
  );
};
