import React from 'react';
import { TestimonialOverImageBlock } from '@klokgroep/sanity/src/corporateQueries/blocks/testimonialBlock';
import { ContentCardBoxedLarge } from '@klokgroep/shared-components/src/ContentCardBoxedLarge';
import { MaxWidth } from '@klokgroep/shared-components/src/MaxWidth';
import { BlockContainerWithBackground } from '@klokgroep/shared-components/src/BlockContainerWithBackground';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { PortableTextWithLinks } from '@klokgroep/shared-components/src/PortableTextWithLinks';
import { FlexRow } from '@klokgroep/shared-components/src/FlexRow';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';
import { renderButtonOrLink } from '@klokgroep/shared-components/utils/renderButtonOrLink';

const QuoteAuthor = ({ authorName, authorRole }: { authorName?: string; authorRole?: string }) => (
  <div>
    {authorName ? <div>{authorName}</div> : undefined}
    {authorRole ? <div>{authorRole}</div> : undefined}
  </div>
);

export const BlockTestimonialOverImage = ({
  image,
  text,
  buttons,
  quoteAuthorName,
  quoteAuthorRole,
  settings,
}: TestimonialOverImageBlock) => {
  const { locale, theme } = useSiteInfo();

  return settings && settings?.isFullWidth ? (
    <ContentCardBoxedLarge image={image} alignContent="right" isFullWidth>
      {text ? (
        <RichText extraLarge>
          <PortableTextWithLinks content={text} />
        </RichText>
      ) : undefined}
      {quoteAuthorName || quoteAuthorRole ? (
        <QuoteAuthor authorName={quoteAuthorName} authorRole={quoteAuthorRole} />
      ) : undefined}
      {buttons && buttons.length > 0 ? (
        <FlexRow noAutoFill wrap>
          {buttons.map((button, index) => (
            <React.Fragment key={index}>
              {renderButtonOrLink({ ...button, locale }, theme === 'vandeklok', 'orange')}
            </React.Fragment>
          ))}
        </FlexRow>
      ) : undefined}
    </ContentCardBoxedLarge>
  ) : (
    <BlockContainerWithBackground backgroundColor={settings?.backgroundColor}>
      <MaxWidth>
        <ContentCardBoxedLarge image={image} alignContent="right">
          {text ? (
            <RichText extraLarge>
              <PortableTextWithLinks content={text} />
            </RichText>
          ) : undefined}
          {quoteAuthorName || quoteAuthorRole ? (
            <QuoteAuthor authorName={quoteAuthorName} authorRole={quoteAuthorRole} />
          ) : undefined}
          {buttons && buttons.length > 0 ? (
            <FlexRow noAutoFill wrap>
              {buttons.map((button, index) => (
                <React.Fragment key={index}>
                  {renderButtonOrLink({ ...button, locale }, theme === 'vandeklok', 'orange')}
                </React.Fragment>
              ))}
            </FlexRow>
          ) : undefined}
        </ContentCardBoxedLarge>
      </MaxWidth>
    </BlockContainerWithBackground>
  );
};
