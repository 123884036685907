import React, { useMemo } from 'react';
import Link from 'next/link';
import styles from './ContentCard.module.css';
import { SourceSetSanityImage } from '@klokgroep/shared-components/src/SourceSet';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { createHref, LinkType } from '@klokgroep/sanity';
import cx from 'classnames';
import { Arrow } from '@klokgroep/shared-components/src/Icons';
import { useSiteInfo } from '../SiteInfoProvider';
import { getImageSizes } from '@klokgroep/shared-components/utils/getImageSizes';

interface Properties extends Omit<Sanity.Keyed<Sanity.Schema.ContentCard>, '_type' | '_key' | 'link'> {
  link?: LinkType;
  subTitle?: string;
  hasVisibleButton?: boolean;
  children?: React.ReactNode;
  imageCrop?: 'square' | 'landscape';
  hasVisibleInnerBox?: boolean;
}

const SIZES = [
  { width: 600, minWidth: 500 },
  { width: 400, minWidth: 300 },
  { width: 200, minWidth: 1 },
];

const RenderFakeButton = ({ label }: { label: string }) => (
  <span className={cx(styles.fakeLink, styles.renderArrow)}>
    {label} <Arrow />
  </span>
);

const InnerContent = ({
  title,
  subTitle,
  subText,
  image,
  link,
  hasVisibleButton,
  hasVisibleInnerBox,
  imageCrop,
  children,
}: Properties) => {
  const { theme } = useSiteInfo();
  const hasInnerContentBox = useMemo(
    () => hasVisibleInnerBox || theme == 'vanerk' || theme == 'vandeklok',
    [theme, hasVisibleInnerBox]
  );

  return (
    <React.Fragment>
      {image ? (
        <div
          className={cx(
            styles.imageHolder,
            hasInnerContentBox ? styles.hasInnerContentBox : undefined,
            styles[`${imageCrop}Crop`]
          )}>
          <SourceSetSanityImage asset={image} loading="eager" sizes={getImageSizes(SIZES, imageCrop)} />
        </div>
      ) : undefined}
      <div
        className={cx(
          styles.contentHolder,
          hasInnerContentBox ? styles.hasInnerContentBox : undefined,
          subTitle ? styles.hasSubTitle : undefined
        )}>
        <RichText>
          {subTitle ? <span className={styles.subTitle}>{subTitle}</span> : undefined}
          <h3 className={cx(styles.title, styles.lineClamp)} style={{ '--line-clamp': 2 } as React.CSSProperties}>
            {title?.replaceAll('*', '')}
          </h3>
          {subText ? <p className={styles.lineClamp}>{subText}</p> : undefined}
          {children}
        </RichText>
        {link && link.label && hasVisibleButton ? <RenderFakeButton label={link.label} /> : undefined}
      </div>
    </React.Fragment>
  );
};

export const ContentCard = ({
  title,
  subTitle,
  subText,
  image,
  link,
  hasVisibleButton = true,
  imageCrop,
  children,
  hasVisibleInnerBox = false,
}: Properties) => {
  const { locale } = useSiteInfo();

  return (link && link.href) || (link && link.externalLink) ? (
    <Link
      className={cx(styles.container, styles.hasLink)}
      href={createHref({ ...link, locale })}
      target={link?.targetBlank ? 'blank' : undefined}>
      <InnerContent
        title={title}
        subTitle={subTitle}
        subText={subText}
        image={image}
        link={link}
        hasVisibleButton={hasVisibleButton}
        hasVisibleInnerBox={hasVisibleInnerBox}
        imageCrop={imageCrop}>
        {children}
      </InnerContent>
    </Link>
  ) : (
    <span className={styles.container}>
      <InnerContent
        title={title}
        subTitle={subTitle}
        subText={subText}
        image={image}
        imageCrop={imageCrop}
        hasVisibleInnerBox={hasVisibleInnerBox}>
        {children}
      </InnerContent>
    </span>
  );
};
