import { Chevron } from '@klokgroep/shared-components/src/Icons';
import { createHref, LinkType } from '@klokgroep/sanity';
import cx from 'classnames';
import Link from 'next/link';
import styles from './LinksList.module.css';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';

export interface LinksListProperties {
  inverted?: boolean;
  items?: LinkType[];
}

export const LinksList = ({ inverted, items = [] }: LinksListProperties) => {
  const { locale } = useSiteInfo();

  return (
    <div>
      {items?.map((link) => (
        <Link
          target={link?.targetBlank ? ' blank' : undefined}
          className={cx(styles.link, { [styles.inverted]: inverted })}
          key={typeof link.href === 'object' ? link.href?.slug?.current : link.href}
          href={createHref({ ...link, locale })}>
          <Chevron />
          <span>{link.label}</span>
        </Link>
      ))}
    </div>
  );
};
