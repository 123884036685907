import {
  BlockContainerWithBackground,
  BlockContainerWithBackgroundProperties,
} from '@klokgroep/shared-components/src/BlockContainerWithBackground';
import { FlexRow } from '@klokgroep/shared-components/src/FlexRow';
import { MaxWidth } from '@klokgroep/shared-components/src/MaxWidth';
import { PentagonContainer } from '@klokgroep/shared-components/src/PentagonContainer';
import { PortableTextWithLinks } from '@klokgroep/shared-components/src/PortableTextWithLinks';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { SanityRichTextType, SanityImageType, VideoWithOptionalTypeAndKey } from '@klokgroep/sanity';
import { SourceSetSanityImage } from '@klokgroep/shared-components/src/SourceSet';
import { TitleOverTwoRules } from '@klokgroep/shared-components/src/TitleOverTwoRules';
import { VideoOrAutoplayVideo } from '@klokgroep/shared-components/src/Video';
import cx from 'classnames';
import React, { ReactNode, useMemo } from 'react';
import styles from './BlockWithImageRichText.module.css';

export interface BlockWithImageRichTextProperties {
  backgroundColor?: BlockContainerWithBackgroundProperties['backgroundColor'];
  children?: ReactNode;
  content?: SanityRichTextType;
  image?: SanityImageType;
  hasPentagonMask?: boolean;
  reversed?: boolean;
  title?: string;
  video?: VideoWithOptionalTypeAndKey;
}

export const BlockWithImageRichText = ({
  backgroundColor,
  children,
  content,
  image,
  hasPentagonMask,
  reversed,
  title,
  video,
}: BlockWithImageRichTextProperties) => (
  <BlockContainerWithBackground backgroundColor={backgroundColor}>
    <MaxWidth>
      <FlexRow mobileReversed reversed={reversed}>
        <div
          className={cx(styles.mediaContainer, 'RemoveGradientMargin', {
            [styles.squareVideoContainer]: !!video && hasPentagonMask,
          })}>
          <MediaElement image={image} hasPentagonMask={hasPentagonMask} video={video} />
        </div>
        <div>
          <RichText>
            {title ? <TitleOverTwoRules as="h2">{title}</TitleOverTwoRules> : undefined}
            {content ? <PortableTextWithLinks content={content} /> : undefined}
          </RichText>
          {children ? <div className={styles.childrenContainer}>{children}</div> : undefined}
        </div>
      </FlexRow>
    </MaxWidth>
  </BlockContainerWithBackground>
);

const MediaElement = ({
  image,
  hasPentagonMask,
  video,
}: {
  image: BlockWithImageRichTextProperties['image'];
  hasPentagonMask: BlockWithImageRichTextProperties['hasPentagonMask'];
  video: BlockWithImageRichTextProperties['video'];
}) => {
  const imageElement = useMemo(() => {
    if (!image?.asset || video?.vimeoVideoUrl || video?.youtubeVideoUrl) {
      return null;
    }

    const imageSizes = [
      { width: 1200 / 2, minWidth: 1100, ratio: image.size === 'small' ? 16 / 10 : 1 },
      { width: 1100 / 2, minWidth: 1000, ratio: image.size === 'small' ? 16 / 10 : 1 },
      { width: 1000 / 2, minWidth: 900, ratio: image.size === 'small' ? 16 / 10 : 1 },
      { width: 900 / 2, minWidth: 800, ratio: image.size === 'small' ? 16 / 10 : 1 },
      { width: 800 / 2, minWidth: 768, ratio: image.size === 'small' ? 16 / 10 : 1 },
      { width: 768 / 2, minWidth: 768, ratio: image.size === 'small' ? 16 / 10 : 1 },
      { width: 700, minWidth: 600, ratio: image.size === 'small' ? 16 / 10 : 1 },
      { width: 600, minWidth: 500, ratio: image.size === 'small' ? 16 / 10 : 1 },
      { width: 500, minWidth: 400, ratio: image.size === 'small' ? 16 / 10 : 1 },
      { width: 400, minWidth: 300, ratio: image.size === 'small' ? 16 / 10 : 1 },
      { width: 300, minWidth: 1, ratio: image.size === 'small' ? 16 / 10 : 1 },
    ];

    return <SourceSetSanityImage asset={image} sizes={imageSizes} />;
  }, [image]);

  if (hasPentagonMask) {
    return (
      <PentagonContainer>
        {video?.vimeoVideoUrl || video?.youtubeVideoUrl ? <VideoOrAutoplayVideo item={video} /> : imageElement}
      </PentagonContainer>
    );
  }

  return video?.vimeoVideoUrl || video?.youtubeVideoUrl ? <VideoOrAutoplayVideo item={video} /> : imageElement;
};
