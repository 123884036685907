import { Arrow, Chevron } from '@klokgroep/shared-components/src/Icons';
import Link from 'next/link';
import React, { HTMLAttributeAnchorTarget } from 'react';
import styles from './LinkWithChevron.module.css';
import cx from 'classnames';
import { useSiteInfo } from '../SiteInfoProvider';

interface Properties {
  label?: string;
  href: string;
  target?: HTMLAttributeAnchorTarget;
  hasSpacing?: boolean;
}

export const LinkWithChevron = ({ label, href, target, hasSpacing = true }: Properties) => {
  const { theme } = useSiteInfo();
  const isHolding = theme == 'holding';

  return (
    <Link
      className={cx(
        styles.label,
        hasSpacing ? styles.hasSpacing : undefined,
        isHolding ? undefined : styles.renderArrow
      )}
      href={href}
      target={target}>
      {label}
      {isHolding ? <Chevron /> : <Arrow />}
    </Link>
  );
};
