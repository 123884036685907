import {
  BlockContainerWithBackground,
  BlockContainerWithBackgroundProperties,
} from '@klokgroep/shared-components/src/BlockContainerWithBackground';
import { Arrow } from '@klokgroep/shared-components/src/Icons';
import { ContentCardBoxedLarge } from '@klokgroep/shared-components/src/ContentCardBoxedLarge';
import { MaxWidth } from '@klokgroep/shared-components/src//MaxWidth';
import { PortableTextWithLinks } from '@klokgroep/shared-components/src/PortableTextWithLinks';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { SanityRichTextType, createHref, ReferenceType } from '@klokgroep/sanity';
import { TitleOverTwoRules } from '@klokgroep/shared-components/src/TitleOverTwoRules';
import { VerticalMargin } from '@klokgroep/shared-components/src/VerticalMargin';
import cx from 'classnames';
import Link from 'next/link';
import React, { Dispatch, useLayoutEffect, useMemo, useState } from 'react';
import styles from './VerticalCarouselBlock.module.css';

interface ImageAndTextProperties {
  _key?: string;
  title?: string;
  text?: SanityRichTextType;
  button?: {
    _key?: string;
    _type?: any;
    type?: 'reference' | 'external' | 'noLink';
    label?: string;
    externalLink?: string;
    targetBlank?: boolean;
    href?: {
      _type?: any;
      slug?: {
        current?: string;
      };
    };
    parent?: ReferenceType;
  };
  image?: Sanity.Image;
  isBoxed?: boolean;
}

interface Properties extends Omit<Sanity.Schema.VerticalCarouselWithTitles, 'imageAndTextItems'> {
  backgroundColor?: BlockContainerWithBackgroundProperties['backgroundColor'];
  imageAndTextItems?: ImageAndTextProperties[];
}

export const VerticalCarouselBlock = ({ carouselItems = [], backgroundColor, title, description }: Properties) => {
  const [activeCarouselIndex, setActiveCarouselIndex] = useState(0);

  return (
    <BlockContainerWithBackground backgroundColor={backgroundColor} smallPaddingTop={!!title || !!description}>
      <MaxWidth>
        {(!!title || !!description) && (
          <div className={styles.titleContainer}>
            {!!title && (
              <RichText>
                <TitleOverTwoRules>{title}</TitleOverTwoRules>
              </RichText>
            )}
            {!description && <VerticalMargin />}
            {!!description && <PortableTextWithLinks content={description} />}
            <VerticalMargin />
          </div>
        )}
        <ButtonsAndSlider
          activeCarouselIndex={activeCarouselIndex}
          setActiveCarouselIndex={setActiveCarouselIndex}
          backgroundColor={backgroundColor}
          carouselItems={carouselItems}
        />
      </MaxWidth>
    </BlockContainerWithBackground>
  );
};

interface ButtonAndSliderProperties extends Omit<Properties, '_type'> {
  activeCarouselIndex: number;
  setActiveCarouselIndex: Dispatch<React.SetStateAction<number>>;
}

const ButtonsAndSlider = ({
  activeCarouselIndex,
  backgroundColor,
  carouselItems,
  setActiveCarouselIndex,
}: ButtonAndSliderProperties) => {
  const [screenWidth, setScreenWidth] = useState<number>(1000);

  useLayoutEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = useMemo(() => screenWidth < 1024, [screenWidth]);

  return (
    <div className={styles.container}>
      <ul className={styles.carouselButtonsContainer}>
        {carouselItems?.map((item, index) => {
          const isActive = index === activeCarouselIndex;
          return (
            <li key={item._key} className={isActive ? styles.hideBorder : undefined}>
              <button
                type="button"
                className={cx(styles.carouselButton, {
                  [styles.lightText]: backgroundColor === 'blue' || backgroundColor === 'black',
                  [styles.active]: isActive,
                })}
                onClick={() => setActiveCarouselIndex(index)}
                aria-label={`Show ${item.buttonLabel}`}>
                <span>{item.buttonLabel}</span>
                <Arrow />
              </button>
              {!!isMobile && index == activeCarouselIndex && !!item?.imageAndTextItem && (
                <Slide
                  index={index}
                  activeCarouselIndex={activeCarouselIndex}
                  imageAndTextItem={item?.imageAndTextItem}
                  isMobile={isMobile}
                />
              )}
            </li>
          );
        })}
      </ul>
      {!isMobile && <VerticalSlider carouselItems={carouselItems} activeCarouselIndex={activeCarouselIndex} />}
    </div>
  );
};

interface VerticalCarouselSlideProperties extends Omit<Properties, '_type' | 'imageAndTextItems'> {
  activeCarouselIndex: number;
  imageAndTextItems?: ImageAndTextProperties[];
}

const VerticalSlider = ({ carouselItems, activeCarouselIndex }: VerticalCarouselSlideProperties) => {
  if (!carouselItems || !carouselItems[0] || !carouselItems[0].imageAndTextItem) return null;

  return (
    <div className={styles.verticalSliderContainer}>
      {carouselItems?.map(({ imageAndTextItem, _key }, index) => (
        <Slide
          key={_key}
          index={index}
          activeCarouselIndex={activeCarouselIndex}
          imageAndTextItem={imageAndTextItem}
          isMobile={false}
        />
      ))}
    </div>
  );
};

interface SlideProperties extends Omit<Properties, '_type'> {
  index: number;
  activeCarouselIndex: number;
  imageAndTextItem?: ImageAndTextProperties;
  isMobile?: boolean;
}

const Slide = (properties: SlideProperties) => {
  const activeCarouselIndex = properties?.activeCarouselIndex;
  const index = properties?.index;
  const button = properties?.imageAndTextItem?.button;

  return button ? (
    <Link
      href={createHref(button)}
      target={button.targetBlank ? 'blank' : undefined}
      className={cx(styles.slideContainer, { [styles.active]: index === activeCarouselIndex })}>
      <SlideInner {...properties} />
    </Link>
  ) : (
    <div className={cx(styles.slideContainer, { [styles.active]: index === activeCarouselIndex })}>
      <SlideInner {...properties} />
    </div>
  );
};

const SlideInner = ({ imageAndTextItem, isMobile }: SlideProperties) => {
  const title = imageAndTextItem?.title;
  const text = imageAndTextItem?.text;
  const button = imageAndTextItem?.button;

  return (
    <ContentCardBoxedLarge contentFullWidth image={imageAndTextItem?.image} gradientHardness={35} gradientDegree={45}>
      <div className={styles.slideInnerContainer}>
        {!!title && (
          <RichText>
            <h3>{title}</h3>
          </RichText>
        )}
        {!!text && !isMobile && (
          <RichText>
            <PortableTextWithLinks content={text} />
          </RichText>
        )}
        {button ? (
          <Link
            href={createHref(button)}
            target={button.targetBlank ? 'blank' : undefined}
            className={styles.buttonLink}>
            <span className={styles.buttonInner}>
              {button?.label} <Arrow />
            </span>
          </Link>
        ) : undefined}
      </div>
    </ContentCardBoxedLarge>
  );
};
