import { ReactNode } from 'react';
import cx from 'classnames';
import styles from './FlexRow.module.css';

interface Properties {
  alignCenter?: boolean;
  alignEnd?: boolean;
  children: ReactNode;
  mobileReversed?: boolean;
  reversed?: boolean;
  gap?: number;
  wrap?: boolean;
  noAutoFill?: boolean;
}

export const FlexRow = ({
  alignCenter,
  alignEnd,
  children,
  gap,
  mobileReversed,
  reversed,
  wrap,
  noAutoFill,
}: Properties) => {
  const className = cx(styles.container, {
    [styles.alignCenter]: alignCenter,
    [styles.alignEnd]: alignEnd,
    [styles.mobileReversed]: mobileReversed,
    [styles.reversed]: reversed,
    [styles.wrap]: wrap,
    [styles.noAutoFill]: noAutoFill,
  });
  return (
    <div className={className} style={{ gap: gap ? `${gap}px` : undefined }}>
      {children}
    </div>
  );
};
