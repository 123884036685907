import React from 'react';
import Link from 'next/link';
import styles from './ContentCardBoxed.module.css';
import { SourceSetSanityImage } from '@klokgroep/shared-components/src/SourceSet';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { createHref, LinkType } from '@klokgroep/sanity';
import cx from 'classnames';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';

interface Properties extends Omit<Sanity.Keyed<Sanity.Schema.ContentCard>, '_type' | '_key' | 'link'> {
  link?: LinkType;
}

const SIZES = [
  { width: 400, minWidth: 300, ratio: 1 },
  { width: 200, minWidth: 1, ratio: 1 },
];

const InnerContent = ({ title, subText, image }: Properties) => (
  <React.Fragment>
    <RichText>
      <div className={styles.richText}>
        <h3 className={styles.title}>{title}</h3>
        {subText ? <span className={styles.subText}>{subText}</span> : undefined}
      </div>
    </RichText>
    {image ? <SourceSetSanityImage asset={image} loading="eager" sizes={SIZES} /> : undefined}
  </React.Fragment>
);

export const ContentCardBoxed = ({ title, subText, image, link }: Properties) => {
  const { locale } = useSiteInfo();

  return (link && link.href) || (link && link.externalLink) ? (
    <Link
      className={cx(styles.container, styles.hasLink)}
      href={createHref({ ...link, locale })}
      target={link?.targetBlank ? 'blank' : undefined}>
      <InnerContent title={title} subText={subText} image={image} />
    </Link>
  ) : (
    <span className={styles.container}>
      <InnerContent title={title} subText={subText} image={image} />
    </span>
  );
};
