import { BlockContainerWithBackground } from '@klokgroep/shared-components/src/BlockContainerWithBackground';
import { EmbedBlockProperties } from '@klokgroep/sanity/src/queries/blocks/embed';
import { MaxWidth } from '@klokgroep/shared-components/src/MaxWidth';
import cx from 'classnames';
import styles from './EmbedBlock.module.css';

export const EmbedBlock = ({ aspectRatio, backgroundColor, url }: EmbedBlockProperties) => (
  <BlockContainerWithBackground backgroundColor={backgroundColor}>
    <MaxWidth>
      {/* eslint-disable-next-line react/iframe-missing-sandbox */}
      <iframe
        className={cx(styles.iframe, {
          [styles.fourBy3]: aspectRatio === '4x3',
          [styles.square]: aspectRatio === '1x1',
        })}
        title={`Url voor ${url}`}
        src={url}
      />
    </MaxWidth>
  </BlockContainerWithBackground>
);
