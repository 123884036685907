import { Button } from '@klokgroep/shared-components/src/Button';
import { FormEventHandler, Fragment, ReactNode } from 'react';
import styles from './Form.module.css';
import { Loader } from '@klokgroep/shared-components/src/Loader';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { useCommonTranslations } from '@klokgroep/shared-components/utils/useTranslations';
import { VerticalMargin } from '@klokgroep/shared-components/src/VerticalMargin';

interface Properties {
  isLoading?: boolean;
  buttonLabel?: string;
  hideButton?: boolean;
  children: ReactNode;
  error?: string;
  onSubmit: FormEventHandler<HTMLFormElement>;
  loaderInverted?: boolean;
  success?: boolean;
}

export const Form = ({
  buttonLabel = 'Verzenden',
  hideButton = false,
  children,
  error,
  onSubmit,
  isLoading,
  loaderInverted = false,
  success,
}: Properties) => {
  const { theme } = useSiteInfo();
  const t = useCommonTranslations();

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      {success ? (
        <Success />
      ) : (
        <div>
          {children}
          <div className={styles.buttonAndLoader}>
            {!hideButton && (
              <Button disabled={isLoading} type="submit" theme={theme === 'vandeklok' ? 'orange' : undefined}>
                {buttonLabel}
              </Button>
            )}
            {!!isLoading && <p>{t('form_loading_text')}</p>}
            {!!isLoading && <Loader inverted={loaderInverted} />}
          </div>
          {!!error && (
            <Fragment>
              <VerticalMargin size="small" />
              <div className={styles.error}>{error}</div>
            </Fragment>
          )}
        </div>
      )}
    </form>
  );
};

const Success = () => {
  const t = useCommonTranslations();
  return (
    <RichText>
      <h2>
        <strong>{t('thank_you')}</strong>
      </h2>
      <p>{t('form_success_message')}</p>
    </RichText>
  );
};
