import {
  BlockContainerWithBackground,
  BlockContainerWithBackgroundProperties,
} from '@klokgroep/shared-components/src/BlockContainerWithBackground';
import { createHref, LinkType, SanityImageType } from '@klokgroep/sanity';
import { ImageHover } from '@klokgroep/shared-components/src/ImageHover/ImageHover';
import { LinkWithChevron } from '@klokgroep/shared-components/src/LinkWithChevron';
import { MaxWidth } from '@klokgroep/shared-components/src/MaxWidth';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { SourceSetSanityImage } from '@klokgroep/shared-components/src/SourceSet';
import { TitleOverTwoRules } from '@klokgroep/shared-components/src/TitleOverTwoRules';
import { useMemo } from 'react';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';
import { VideoOrAutoplayVideo } from '@klokgroep/shared-components/src/Video';
import Link from 'next/link';
import styles from './BlockWithImagesAndLinks.module.css';

interface BlockWithImagesAndLinksProperties {
  backgroundColor?: BlockContainerWithBackgroundProperties['backgroundColor'];
  title?: string;
  items?: {
    alternativeImage?: SanityImageType;
    image?: SanityImageType;
    link?: LinkType;
    video?: {
      autoPlay?: boolean;
      vimeoVideoUrl?: string;
      youtubeVideoUrl?: string;
    };
  }[];
}

export const BlockWithImagesAndLinks = ({ backgroundColor, items, title }: BlockWithImagesAndLinksProperties) => (
  <BlockContainerWithBackground backgroundColor={backgroundColor}>
    <MaxWidth>
      <div className={styles.titleContainer}>
        <RichText>
          <TitleOverTwoRules as="h2">{title}</TitleOverTwoRules>
        </RichText>
      </div>
      <div className={styles.itemsContainer}>
        {items?.map((item, index) =>
          item?.link?.type === 'noLink' ? (
            <BlockWithImageAndText key={index} item={item} />
          ) : (
            <BlockWithImageAndLink key={index} item={item} />
          )
        )}
      </div>
    </MaxWidth>
  </BlockContainerWithBackground>
);

const IMAGE_SIZES = [
  { ratio: 4 / 3, width: 400, minWidth: 300 },
  { ratio: 4 / 3, width: 300, minWidth: 1 },
];

const BlockWithImageAndLink = ({ item }: { item: NonNullable<BlockWithImagesAndLinksProperties['items']>[number] }) => {
  const { locale } = useSiteInfo();

  return (
    <div className={styles.container}>
      <ImageHover.Container>
        {item.video?.youtubeVideoUrl ? (
          <MediaPart item={item} />
        ) : (
          <Link href={createHref({ ...item.link, locale })} target={item.link?.targetBlank ? 'blank' : undefined}>
            <MediaPart item={item} />
          </Link>
        )}
        {item.link?.label ? (
          <LinkWithChevron
            label={item.link?.label}
            href={createHref({ ...item.link, locale })}
            target={item.link?.targetBlank ? 'blank' : undefined}
          />
        ) : !!item.link?.label && !item?.link?.href ? (
          <div className={styles.label}>{item?.link?.label}</div>
        ) : undefined}
      </ImageHover.Container>
    </div>
  );
};

const BlockWithImageAndText = ({ item }: { item: NonNullable<BlockWithImagesAndLinksProperties['items']>[number] }) => (
  <div className={styles.container}>
    <MediaPart item={item} />
    <div className={styles.label}>{item.link?.label}</div>
  </div>
);

const MediaPart = ({ item }: { item: NonNullable<BlockWithImagesAndLinksProperties['items']>[number] }) => {
  const image = useMemo(
    () => (item.alternativeImage?.asset ? item.alternativeImage : item.image),
    [item.alternativeImage, item.image]
  );

  return (
    <div className={styles.mediaContainer}>
      {item.video?.youtubeVideoUrl || item.video?.vimeoVideoUrl ? (
        <VideoOrAutoplayVideo item={item.video} resizeToContainer />
      ) : image ? (
        <ImageHover>
          <SourceSetSanityImage asset={image} sizes={IMAGE_SIZES} />
        </ImageHover>
      ) : undefined}
    </div>
  );
};
