import { Input } from '@klokgroep/shared-components/src/Input';
import { TextArea } from '@klokgroep/shared-components/src/TextArea';
import { useCommonTranslations } from '@klokgroep/shared-components/utils/useTranslations';
import { object, string, boolean } from 'yup';

export const getGeneralFormFields = (t: ReturnType<typeof useCommonTranslations>) => {
  const validation = object({
    name: string().required(t('contact_form_required_field_error')),
    organisation: string(),
    email: string().email(t('contact_form_valid_email_error')).required(t('contact_form_required_field_error')),
    phoneNumber: string(),
    message: string().required(t('contact_form_required_field_error')),
    acceptConditions: boolean().oneOf([true], t('contact_form_agree_to_privacy_policy_error')),
  });

  const formFields = [
    {
      placeholder: t('form_block_name_placeholder'),
      description: t('form_block_name'),
      fieldName: 'name',
      type: 'text',
      Component: Input,
    },
    {
      placeholder: t('form_block_organisation_placeholder'),
      description: t('form_block_organisation'),
      fieldName: 'organisation',
      type: 'text',
      Component: Input,
    },
    {
      placeholder: t('contact_form_email_placeholder'),
      description: t('contact_form_email_label'),
      fieldName: 'email',
      type: 'text',
      Component: Input,
    },
    {
      placeholder: t('contact_form_phone_placeholder'),
      description: t('contact_form_phone_label'),
      fieldName: 'phoneNumber',
      type: 'text',
      Component: Input,
    },
    {
      placeholder: t('contact_form_message_placeholder'),
      description: t('contact_form_message_label'),
      fieldName: 'message',
      type: 'text',
      Component: TextArea,
    },
  ];

  return { validation, formFields };
};
