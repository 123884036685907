import React from 'react';
import { ColumnsAndIconsBlock } from '@klokgroep/sanity';
import styles from './BlockColumnsAndIcons.module.css';
import { HeroIcon } from '@klokgroep/shared-components/src/Icons';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { BlockContainerWithBackground } from '@klokgroep/shared-components/src/BlockContainerWithBackground';

export const BlockColumnsAndIcons = ({ backgroundColor, columns }: ColumnsAndIconsBlock) => (
  <BlockContainerWithBackground backgroundColor={backgroundColor}>
    <div className={styles.container}>
      {columns?.map((item) => (
        <div className={styles.itemContainer} key={item._key}>
          <div>
            {!!item.icon && <HeroIcon icon={item.icon} />}
            <RichText>
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </RichText>
          </div>
        </div>
      ))}
    </div>
  </BlockContainerWithBackground>
);
