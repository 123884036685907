import { BlockContainerWithBackground, BlockContainerWithBackgroundProperties } from '../BlockContainerWithBackground';
import { MaxWidth } from '../MaxWidth';
import { PortableTextWithLinks } from '../PortableTextWithLinks';
import { RichText } from '../RichText';
import { SanityImageType, SanityRichTextType } from '@klokgroep/sanity';
import { SourceSetSanityImage } from '../SourceSet';
import styles from './BlockWithUspWithLogoAndRichText.module.css';

interface Properties extends UspWithLogoAndRichTextProperties {
  backgroundColor?: BlockContainerWithBackgroundProperties['backgroundColor'];
}

export const BlockWithUspWithLogoAndRichText = ({ backgroundColor, ...rest }: Properties) => (
  <BlockContainerWithBackground backgroundColor={backgroundColor}>
    <MaxWidth size="medium">
      <UspWithLogoAndRichText {...rest} />
    </MaxWidth>
  </BlockContainerWithBackground>
);

interface UspWithLogoAndRichTextProperties {
  image?: SanityImageType;
  content?: SanityRichTextType;
}

const LOGO_SIZES = [{ width: 200, minWidth: 1 }];

const UspWithLogoAndRichText = ({ image, content }: UspWithLogoAndRichTextProperties) => (
  <div className={styles.container}>
    {image ? <SourceSetSanityImage asset={image} sizes={LOGO_SIZES} /> : undefined}
    {content ? (
      <RichText>
        <PortableTextWithLinks content={content} />
      </RichText>
    ) : undefined}
  </div>
);
