import {
  BlockWithImageRichText,
  BlockWithImageRichTextProperties,
} from '@klokgroep/shared-components/src/BlockWithImageRichText';
import { LinksList, LinksListProperties } from '@klokgroep/shared-components/src/LinksList';

interface Properties extends BlockWithImageRichTextProperties {
  items: LinksListProperties['items'];
}

export const BlockWithImageAndLinks = ({
  content,
  backgroundColor,
  image,
  items,
  reversed,
  title,
  video,
}: Properties) => (
  <BlockWithImageRichText
    content={content}
    title={title}
    backgroundColor={backgroundColor}
    image={image}
    reversed={reversed}
    video={video}>
    <LinksList items={items} inverted={backgroundColor === 'blue'} />
  </BlockWithImageRichText>
);
