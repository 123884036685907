import React, { forwardRef, HTMLAttributes, useMemo } from 'react';
import { InputContainer, InputContainerProperties } from '@klokgroep/shared-components/src/InputContainer';
import styles from './TextArea.module.css';
import cx from 'classnames';

interface TextAreaProperties extends Omit<InputContainerProperties, 'children'>, HTMLAttributes<HTMLTextAreaElement> {}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProperties>(
  ({ description, error, label, ...rest }, reference) => {
    const classNames = useMemo(() => cx(styles.textArea, { [styles.error]: !!error }), [error]);

    return (
      <InputContainer description={description} error={error} label={label}>
        <textarea className={classNames} ref={reference} {...rest} />
      </InputContainer>
    );
  }
);

TextArea.displayName = 'TextArea';
