import { BlockColumnsAndIcons } from '@klokgroep/shared-components/src/BlockColumnsAndIcons';
import { BlockTestimonialOverImage } from '@klokgroep/shared-components/src/BlockTestimonialOverImage';
import { BlockTextOverImage } from '@klokgroep/shared-components/src/BlockTextOverImage';
import { BlockTextWithContentCards } from '@klokgroep/shared-components/src/BlockTextWithContentCards';
import { BlockTwoColumns } from '@klokgroep/shared-components/src/BlockTwoColumns';
import { BlockWithCallToAction } from '@klokgroep/shared-components/src/BlockWithCallToAction';
import { BlockWithImageAndButtonLink } from '@klokgroep/shared-components/src/BlockWithImageAndButtonLink';
import { BlockWithImageAndFoldouts } from '@klokgroep/shared-components/src/BlockWithImageAndFoldouts';
import { BlockWithImageAndLinks } from '@klokgroep/shared-components/src/BlockWithImageAndLinks';
import { BlockWithImageRichText } from '@klokgroep/shared-components/src/BlockWithImageRichText';
import { BlockWithImagesAndLinks } from '@klokgroep/shared-components/src/BlockWithImagesAndLinks';
import { BlockWithPeopleGrid } from '@klokgroep/shared-components/src/BlockWithPeopleGrid';
import { BlockWithRichText } from '@klokgroep/shared-components/src/BlockWithRichText';
import { BlockWithTextAndFoldouts } from '@klokgroep/shared-components/src/BlockWithTextAndFoldouts';
import { BlockWithTitleAndMultipleRichText } from '@klokgroep/shared-components/src/BlockWithTitleAndMultipleRichText';
import { BlockWithUspWithLogoAndRichText } from '@klokgroep/shared-components/src/BlockWithUspWithLogoAndRichText';
import { BlockWithXlImageRichTextAndButton } from '@klokgroep/shared-components/src/BlockWithXlImageRichTextAndButton';
import { EmbedBlock } from '@klokgroep/shared-components/src/EmbedBlock';
import { FormBlock } from '@klokgroep/shared-components/src/FormBlock';
import { MappedCorporateContentModules } from '@klokgroep/sanity';
import { MediaBlock } from '@klokgroep/shared-components/src/MediaBlock';
import { RelatedContentBlock } from '@klokgroep/shared-components/src/RelatedContentBlock';
import { VerticalCarouselBlock } from '@klokgroep/shared-components/src/VerticalCarouselBlock';
import { BlockNewsletterSignUp } from '@klokgroep/shared-components/src/BlockNewsletterSignUp';
import styles from './CorporateContentModules.module.css';

interface Properties {
  modules: MappedCorporateContentModules;
  isProjects?: boolean;
}

const MODULES = {
  ['blockWithColumnsAndIcons' as const]: BlockColumnsAndIcons,
  ['callToAction' as const]: BlockWithCallToAction,
  ['embed' as const]: EmbedBlock,
  ['formBlock' as const]: FormBlock,
  ['imageAndButton' as const]: BlockWithImageAndButtonLink,
  ['imageAndFoldouts' as const]: BlockWithImageAndFoldouts,
  ['imageAndLinks' as const]: BlockWithImageAndLinks,
  ['imageAndNewsletterSignup' as const]: BlockNewsletterSignUp,
  ['imageAndText' as const]: BlockWithImageRichText,
  ['imagesWithLinks' as const]: BlockWithImagesAndLinks,
  ['xlImageWithRichTextAndButton' as const]: BlockWithXlImageRichTextAndButton,
  ['mediaBlock' as const]: MediaBlock,
  ['peopleGrid' as const]: BlockWithPeopleGrid,
  ['relatedContent' as const]: RelatedContentBlock,
  ['richText' as const]: BlockWithRichText,
  ['testimonialOverImage' as const]: BlockTestimonialOverImage,
  ['textAndFoldouts' as const]: BlockWithTextAndFoldouts,
  ['textOverImage' as const]: BlockTextOverImage,
  ['textWithContentCards' as const]: BlockTextWithContentCards,
  ['titleMultipleRichText' as const]: BlockWithTitleAndMultipleRichText,
  ['twoColumns' as const]: BlockTwoColumns,
  ['uspWithLogoAndText' as const]: BlockWithUspWithLogoAndRichText,
  ['verticalCarouselWithTitles' as const]: VerticalCarouselBlock,
};

// TODO: fix these types
export const CorporateContentModules = ({ modules, isProjects }: Properties) => (
  <div className={isProjects ? styles.projectsContainer : undefined}>
    {modules?.map((item, index) => {
      const Module = MODULES[item._type];

      if (!Module) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line unicorn/prefer-ternary
      if (isProjects && index === 0 && item.backgroundColor === 'white') {
        return (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <div key={`${item._key}-${index}`} className={styles.firstItemContainer}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Module {...item} />;
          </div>
        );
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return <Module key={`${item._key}-${index}`} {...item} />;
      }
    })}
  </div>
);
