import {
  BlockContainerWithBackground,
  BlockContainerWithBackgroundProperties,
} from '@klokgroep/shared-components/src/BlockContainerWithBackground';
import { FlexRow } from '@klokgroep/shared-components/src/FlexRow';
import { FoldOutsWithLogos, FoldOutsWithLogosProperties } from '@klokgroep/shared-components/src/FoldOutsWithLogos';
import { MaxWidth } from '@klokgroep/shared-components/src/MaxWidth';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { TitleOverTwoRules } from '@klokgroep/shared-components/src/TitleOverTwoRules';
import React from 'react';
import { PortableTextWithLinks } from '@klokgroep/shared-components/src/PortableTextWithLinks';
import { SanityRichTextType } from '@klokgroep/sanity';

interface Properties {
  content?: SanityRichTextType;
  backgroundColor?: BlockContainerWithBackgroundProperties['backgroundColor'];
  items: FoldOutsWithLogosProperties['items'];
  reversed?: boolean;
  title?: string;
}

export const BlockWithTextAndFoldouts = ({ content, backgroundColor, items, reversed, title }: Properties) => (
  <BlockContainerWithBackground backgroundColor={backgroundColor}>
    <MaxWidth>
      <FlexRow reversed={reversed}>
        <div>
          <FoldOutsWithLogos items={items} inverted={backgroundColor === 'blue'} />
        </div>
        <div>
          <RichText>
            {title ? <TitleOverTwoRules as="h2">{title}</TitleOverTwoRules> : undefined}
            {content ? <PortableTextWithLinks content={content} /> : undefined}
          </RichText>
        </div>
      </FlexRow>
    </MaxWidth>
  </BlockContainerWithBackground>
);
