import { createHref, LinkType } from '@klokgroep/sanity';
import {
  BlockWithImageRichText,
  BlockWithImageRichTextProperties,
} from '@klokgroep/shared-components/src/BlockWithImageRichText';
import { ButtonLink } from '@klokgroep/shared-components/src/Button';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';

interface Properties extends BlockWithImageRichTextProperties {
  button?: LinkType;
}

export const BlockWithImageAndButtonLink = ({
  backgroundColor,
  button,
  content,
  image,
  reversed,
  title,
  video,
}: Properties) => {
  const { locale } = useSiteInfo();

  return (
    <BlockWithImageRichText
      content={content}
      backgroundColor={backgroundColor}
      image={image}
      reversed={reversed}
      hasPentagonMask
      title={title}
      video={video}>
      {button?.label ? (
        <ButtonLink href={createHref({ ...button, locale })} target={button?.targetBlank ? 'blank' : undefined}>
          {button?.label}
        </ButtonLink>
      ) : undefined}
    </BlockWithImageRichText>
  );
};
