import { BlockContainerWithBackground } from '@klokgroep/shared-components/src/BlockContainerWithBackground';
import { ButtonLink } from '@klokgroep/shared-components/src/Button';
import { CallToActionBlock } from '@klokgroep/sanity/src/queries/blocks/callToAction';
import { createHref } from '@klokgroep/sanity';
import { FlexRow } from '@klokgroep/shared-components/src/FlexRow';
import { MaxWidth } from '@klokgroep/shared-components/src/MaxWidth';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { SourceSetSanityImage } from '@klokgroep/shared-components/src/SourceSet';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';
import styles from './BlockWithCallToAction.module.css';

const IMAGE_SIZES = [
  { width: 340, minWidth: 1024, ratio: 4 / 3 },
  { width: 500, minWidth: 768, ratio: 4 / 3 },
  { width: 768, minWidth: 700, ratio: 4 / 3 },
  { width: 600, minWidth: 500, ratio: 4 / 3 },
  { width: 400, minWidth: 300, ratio: 4 / 3 },
  { width: 200, minWidth: 1, ratio: 4 / 3 },
];

export const BlockWithCallToAction = ({ image, title, text, buttons, backgroundColor }: CallToActionBlock) => {
  const { locale, theme } = useSiteInfo();

  return (
    <BlockContainerWithBackground backgroundColor={backgroundColor || 'gray'}>
      <MaxWidth size="mediumLarge">
        <div className={styles.container}>
          {image?.asset ? (
            <div className={styles.imageContainer}>
              <SourceSetSanityImage asset={image} loading="lazy" sizes={IMAGE_SIZES} />
            </div>
          ) : undefined}
          <div className={styles.contentContainer}>
            <RichText>
              {title ? <h2>{title}</h2> : undefined}
              {text ? <p>{text}</p> : undefined}
            </RichText>

            {buttons && buttons.length > 0 ? (
              <FlexRow noAutoFill wrap>
                {buttons.map((button, index) => (
                  <ButtonLink
                    key={createHref({ ...button, locale })}
                    href={createHref({ ...button, locale })}
                    target={button.targetBlank ? 'blank' : undefined}
                    theme={theme === 'novaform' ? 'redText' : index === 0 ? 'orange' : 'outline'}>
                    {button.label}
                  </ButtonLink>
                ))}
              </FlexRow>
            ) : undefined}
          </div>
        </div>
      </MaxWidth>
    </BlockContainerWithBackground>
  );
};
