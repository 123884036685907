import { RelatedContentBlockProperties } from '@klokgroep/sanity/src/queries/blocks/relatedContent';
import { BlockContainerWithBackground } from '@klokgroep/shared-components/src/BlockContainerWithBackground';
import { PagePreviewItem } from '@klokgroep/shared-components/src/PagePreviewItem';
import { ItemsInGridWithTitle } from '@klokgroep/shared-components/src/ItemsInGridWithTitle';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';
import { useMemo } from 'react';
import { getFilteredRelatedItems } from '@klokgroep/shared-components/utils/getFilteredRelatedItems';

export const RelatedContentBlock = ({ items, title, backgroundColor, documentId }: RelatedContentBlockProperties) => {
  const { theme } = useSiteInfo();

  const uniqueItems = useMemo(
    () => getFilteredRelatedItems<RelatedContentBlockProperties['items']>(documentId, items),
    [documentId, items]
  );

  return (
    <BlockContainerWithBackground backgroundColor={backgroundColor}>
      <ItemsInGridWithTitle title={title} parentBackgroundColor={theme === 'novaform' ? 'black' : backgroundColor}>
        {uniqueItems?.map(
          ({ link, previewText, title, imageAsset, categories, _id, _type, corporateProjectFields, ...rest }) => (
            <PagePreviewItem
              key={_id}
              imageAsset={imageAsset}
              title={title}
              type={_type}
              previewText={getPreviewText(previewText, _type, corporateProjectFields)}
              link={link}
              category={categories ? categories[0] : undefined}
              backgroundColor={backgroundColor}
              {...rest}
            />
          )
        )}
      </ItemsInGridWithTitle>
    </BlockContainerWithBackground>
  );
};

type Item = NonNullable<RelatedContentBlockProperties['items']>[number];

function getPreviewText(
  previewText: Item['previewText'],
  _type: Item['_type'],
  corporateProjectFields: Item['corporateProjectFields']
) {
  if (_type === 'corporate-project') {
    const projectItemsString = corporateProjectFields?.filter(Boolean).join(' • ');
    return (projectItemsString?.length || 0) > 0 ? projectItemsString : previewText;
  }

  return previewText;
}
