import { BlockContainerWithBackground } from '@klokgroep/shared-components/src/BlockContainerWithBackground';
import { FlexRow } from '@klokgroep/shared-components/src/FlexRow';
import { MaxWidth } from '@klokgroep/shared-components/src/MaxWidth';
import { FlexColumn } from '@klokgroep/shared-components/src/FlexColumn';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { PortableTextWithLinks } from '@klokgroep/shared-components/src/PortableTextWithLinks';
import { TwoColumnsBlock } from '@klokgroep/sanity/src/corporateQueries/blocks/twoColumns';
import { SourceSetSanityImage } from '@klokgroep/shared-components/src/SourceSet';
import { useMemo } from 'react';
import styles from './BlockTwoColumns.module.css';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';
import { getImageSizes } from '@klokgroep/shared-components/utils/getImageSizes';
import { renderButtonOrLink } from '@klokgroep/shared-components/utils/renderButtonOrLink';

const SIZES = [
  { width: 1400, minWidth: 1300 },
  { width: 1300, minWidth: 1200 },
  { width: 1000, minWidth: 900 },
  { width: 800, minWidth: 700 },
  { width: 600, minWidth: 500 },
  { width: 400, minWidth: 1 },
];

export const BlockTwoColumns = ({ backgroundColor, columnOne, columnTwo }: TwoColumnsBlock) => {
  const { locale, theme } = useSiteInfo();

  // Build the image in the first column
  const imageColumnOne = useMemo(() => {
    if (!columnOne.image) {
      return;
    }
    return (
      <div className={styles.firstColImageWrapper}>
        <SourceSetSanityImage asset={columnOne.image} sizes={getImageSizes(SIZES, columnOne.image.size)} />
      </div>
    );
  }, [columnOne.image]);

  // Build the image in the first column
  const imageColumnTwo = useMemo(() => {
    if (!columnTwo.image) {
      return;
    }
    return <SourceSetSanityImage asset={columnTwo.image} sizes={getImageSizes(SIZES, columnTwo.image.size)} />;
  }, [columnTwo.image]);

  return (
    <BlockContainerWithBackground backgroundColor={backgroundColor}>
      <MaxWidth>
        <FlexRow>
          {columnOne ? (
            <FlexColumn>
              <RichText>
                {columnOne.content ? <PortableTextWithLinks content={columnOne.content} /> : undefined}
              </RichText>
              {columnOne.button && columnOne.button.label
                ? renderButtonOrLink({ ...columnOne.button, locale }, theme !== 'novaform')
                : undefined}
              {imageColumnOne}
            </FlexColumn>
          ) : undefined}

          {columnTwo ? (
            <FlexColumn>
              {imageColumnTwo}
              <RichText>
                {columnTwo.content ? <PortableTextWithLinks content={columnTwo.content} /> : undefined}
              </RichText>
              {columnTwo.button && columnTwo.button.label
                ? renderButtonOrLink({ ...columnTwo.button, locale }, theme !== 'novaform')
                : undefined}
            </FlexColumn>
          ) : undefined}
        </FlexRow>
      </MaxWidth>
    </BlockContainerWithBackground>
  );
};
