import React, { useCallback, useState } from 'react';
import { BlockContainerWithBackground } from '@klokgroep/shared-components/src/BlockContainerWithBackground';
import { FlexRow } from '@klokgroep/shared-components/src/FlexRow';
import { Form } from '@klokgroep/shared-components/src/Form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCommonTranslations } from '@klokgroep/shared-components/utils/useTranslations';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { PortableTextWithLinks } from '@klokgroep/shared-components/src/PortableTextWithLinks';
import { MaxWidth } from '@klokgroep/shared-components/src/MaxWidth';
import { InputProperties } from '@klokgroep/shared-components/src/Input';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';
import styles from './FormBlock.module.css';
import Link from 'next/link';
import { ROUTES } from '@klokgroep/shared-components/utils/ROUTES';
import { FormBlockProperties } from '@klokgroep/sanity/src/queries/blocks/formBlock';
import { getGeneralFormFields } from '@klokgroep/shared-components/utils/getGeneralFormFields';
import { trackFormSubmit } from '@klokgroep/shared-components/utils/tracking';
import { TitleOverTwoRules } from '@klokgroep/shared-components/src/TitleOverTwoRules';
import { SiteIdType } from '@klokgroep/sanity';

type ExternalFormData = {
  tags?: string[];
  inboundEmail?: string;
  outboundEmail?: string;
  ccInboundEmails?: string[];
  emailConfirmationTemplateName?: string;
  siteId?: SiteIdType;
  mailchimpListId?: string;
};

type FormFields = {
  name: string;
  email: string;
  phoneNumber?: string;
  organisation?: string;
  message: string;
  acceptConditions: boolean;
  acceptNewsletter?: boolean;
};

export type FormData = FormFields & ExternalFormData;

export const FormBlock = ({
  description,
  title,
  tags,
  backgroundColor,
  inboundEmail,
  outboundEmail,
  ccInboundEmails,
  mailchimpListId,
  emailConfirmationTemplateName,
}: FormBlockProperties) => {
  const [formError, setFormError] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState(false);

  const { theme, siteId } = useSiteInfo();

  const t = useCommonTranslations();

  const { formFields, validation } = getGeneralFormFields(t);

  const { register, handleSubmit, formState } = useForm<FormFields>({
    resolver: yupResolver(validation),
  });

  const onSubmit = useCallback(
    async (data: FormFields) => {
      setIsLoading(true);
      setFormError(undefined);
      try {
        const formData = {
          ...data,
          tags,
          siteId,
          mailchimpListId,
          inboundEmail,
          outboundEmail,
          ccInboundEmails,
          emailConfirmationTemplateName,
        };

        await fetch('/api/forms/general', {
          method: 'POST',
          body: JSON.stringify(formData),
          headers: {
            'Content-Type': 'application/json',
          },
        });

        trackFormSubmit('1');

        setSuccess(true);
      } catch {
        setFormError(t('contact_form_general_error'));
      } finally {
        setIsLoading(false);
      }
    },
    [ccInboundEmails, emailConfirmationTemplateName, inboundEmail, mailchimpListId, outboundEmail, siteId, t, tags]
  );

  return (
    <BlockContainerWithBackground backgroundColor={backgroundColor}>
      <MaxWidth>
        <FlexRow>
          <RichText>
            <TitleOverTwoRules as="h2">{title}</TitleOverTwoRules>
            {!!description && <PortableTextWithLinks content={description} />}
          </RichText>
          <Form
            success={success}
            onSubmit={handleSubmit(onSubmit)}
            buttonLabel={t('form_submit_label')}
            isLoading={isLoading}
            error={formError}
            loaderInverted={theme === 'novaform'}>
            <div className={styles.formFields}>
              {!!formFields &&
                Array.isArray(formFields) &&
                formFields.map(({ Component, fieldName, placeholder, description, type }) => {
                  const typedFieldName = fieldName as keyof FormFields;
                  const typedFieldType = type as InputProperties['type'];

                  return (
                    <div key={fieldName} className={styles.field}>
                      <Component
                        type={typedFieldType}
                        error={formState.errors[typedFieldName]?.message}
                        placeholder={placeholder}
                        description={description}
                        id={fieldName}
                        {...register(typedFieldName)}
                      />
                    </div>
                  );
                })}
            </div>
            <div className={styles.checkbox}>
              <input type="checkbox" id="acceptConditions" {...register('acceptConditions')} />
              <label htmlFor="acceptConditions">
                {t.rich('contact_form_agree_to_privacy_policy', {
                  // eslint-disable-next-line react/no-unstable-nested-components
                  privacypolicylink: (chunks) => (
                    <Link href={ROUTES.privacy} target="_blank">
                      {chunks}
                    </Link>
                  ),
                })}
              </label>
              {!!formState.errors.acceptConditions?.message && (
                <div className={styles.error}>{formState.errors.acceptConditions?.message}</div>
              )}
            </div>
            <div className={styles.checkbox}>
              <input type="checkbox" id="acceptNewsletter" {...register('acceptNewsletter')} />
              <label htmlFor="acceptNewsletter">{t('form_block_newsletter_accept_label')}</label>
              {!!formState.errors.acceptNewsletter?.message && (
                <div className={styles.error}>{formState.errors.acceptNewsletter?.message}</div>
              )}
            </div>
          </Form>
        </FlexRow>
      </MaxWidth>
    </BlockContainerWithBackground>
  );
};
