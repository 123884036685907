import {
  BlockContainerWithBackground,
  BlockContainerWithBackgroundProperties,
} from '@klokgroep/shared-components/src/BlockContainerWithBackground';
import { ButtonLink } from '@klokgroep/shared-components/src/Button';
import { createHref } from '@klokgroep/sanity';
import { FlexRow } from '@klokgroep/shared-components/src/FlexRow';
import { Fragment, useCallback, useState } from 'react';
import { ImageHover } from '@klokgroep/shared-components/src/ImageHover/ImageHover';
import { MaxWidth } from '@klokgroep/shared-components/src/MaxWidth';
import { Modal } from '@klokgroep/shared-components/src/Modal';
import { PeopleGridBlock } from '@klokgroep/sanity/src/queries/blocks/peopleGrid';
import { PortableTextWithLinks } from '@klokgroep/shared-components/src/PortableTextWithLinks';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { SourceSetSanityImage } from '@klokgroep/shared-components/src/SourceSet';
import { useCommonTranslations } from '@klokgroep/shared-components/utils/useTranslations';
import styles from './BlockWithPeopleGrid.module.css';

interface Properties extends PeopleGridBlock {
  backgroundColor?: BlockContainerWithBackgroundProperties['backgroundColor'];
}

export const BlockWithPeopleGrid = ({ backgroundColor, people }: Properties) => (
  <BlockContainerWithBackground backgroundColor={backgroundColor}>
    <MaxWidth>
      <div className={styles.grid}>
        {people?.map((person) => (
          <Person key={person.name} {...person} />
        ))}
      </div>
    </MaxWidth>
  </BlockContainerWithBackground>
);

type PersonProperties = NonNullable<Properties['people']>[number];

const PERSON_IMAGE_SIZES = [
  { width: 520, minWidth: 768, ratio: 1 },
  { width: 380, minWidth: 520, ratio: 1 },
  { width: 520, minWidth: 400, ratio: 1 },
  { width: 400, minWidth: 300, ratio: 1 },
  { width: 300, minWidth: 200, ratio: 1 },
  { width: 200, minWidth: 100, ratio: 1 },
  { width: 100, minWidth: 1, ratio: 1 },
];

const Person = ({ name, jobTitle, image, ...rest }: PersonProperties) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = useCallback(() => setIsModalOpen(true), []);
  const closeModal = useCallback(() => setIsModalOpen(false), []);

  return (
    <Fragment>
      <button type="button" className={styles.person} onClick={openModal}>
        <ImageHover.Container>
          <div className={styles.imageGridContainer}>
            {image ? (
              <ImageHover>
                <SourceSetSanityImage asset={image} sizes={PERSON_IMAGE_SIZES} />{' '}
              </ImageHover>
            ) : undefined}
          </div>
          <RichText large>
            <p>
              {name ? <span className={styles.name}>{name}</span> : undefined}
              {jobTitle ? <span className={styles.jobTitle}>{jobTitle}</span> : undefined}
            </p>
          </RichText>
        </ImageHover.Container>
      </button>
      {isModalOpen ? <PersonModal onClose={closeModal} {...{ name, jobTitle, image, ...rest }} /> : undefined}
    </Fragment>
  );
};

type PersonModalProperties = NonNullable<Properties['people']>[number] & {
  onClose: () => void;
};

const PERSON_MODAL_IMAGE_SIZES = [
  { width: 800, minWidth: 900, ratio: 3 / 4 },
  { width: 400, minWidth: 768, ratio: 3 / 4 },
  { width: 768, minWidth: 700, ratio: 1 },
  { width: 700, minWidth: 600, ratio: 1 },
  { width: 600, minWidth: 500, ratio: 1 },
  { width: 500, minWidth: 400, ratio: 1 },
  { width: 400, minWidth: 300, ratio: 1 },
  { width: 300, minWidth: 200, ratio: 1 },
  { width: 200, minWidth: 100, ratio: 1 },
  { width: 100, minWidth: 1, ratio: 1 },
];

const PersonModal = ({
  onClose,
  name,
  jobTitle,
  content,
  image,
  largeImage,
  phoneNumber,
  linkedin,
  link,
}: PersonModalProperties) => {
  const t = useCommonTranslations();

  return (
    <Modal onClose={onClose} showLogo={false} modalSize="large" closeButtonFloating>
      <FlexRow alignEnd>
        <div className={styles.imageContainer}>
          {largeImage ? (
            <SourceSetSanityImage asset={largeImage} sizes={PERSON_MODAL_IMAGE_SIZES} />
          ) : image ? (
            <SourceSetSanityImage asset={image} sizes={PERSON_MODAL_IMAGE_SIZES} />
          ) : undefined}
        </div>
        <RichText>
          {name ? <h2 className={styles.modalTitleName}>{name}</h2> : undefined}
          {jobTitle ? <p className="large">{jobTitle}</p> : undefined}

          {content ? (
            <Fragment>
              <hr className={styles.modalDivider} />
              <PortableTextWithLinks content={content} />
            </Fragment>
          ) : undefined}

          {phoneNumber || linkedin || link?.type ? (
            <Fragment>
              <hr className={styles.modalDivider} />
              <div className={styles.buttonLinksContainer}>
                {phoneNumber ? (
                  <ButtonLink href={`tel:${phoneNumber}`}>
                    {t('call_me_on')} {phoneNumber}
                  </ButtonLink>
                ) : undefined}
                {linkedin ? <ButtonLink href={`tel:${phoneNumber}`}>{t('view_me_on_linkedin')}</ButtonLink> : undefined}
                {link?.type ? (
                  <ButtonLink href={createHref(link)} target={link?.targetBlank ? 'blank' : undefined}>
                    {link?.label}
                  </ButtonLink>
                ) : undefined}
              </div>
            </Fragment>
          ) : undefined}
        </RichText>
      </FlexRow>
    </Modal>
  );
};
