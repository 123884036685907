import { FoldOutsWithLogos, FoldOutsWithLogosProperties } from '@klokgroep/shared-components/src/FoldOutsWithLogos';
import {
  BlockWithImageRichText,
  BlockWithImageRichTextProperties,
} from '@klokgroep/shared-components/src/BlockWithImageRichText';

interface Properties extends BlockWithImageRichTextProperties {
  alwaysOpen?: boolean;
  items?: null | FoldOutsWithLogosProperties['items'];
}

export const BlockWithImageAndFoldouts = ({
  alwaysOpen,
  content,
  backgroundColor,
  image,
  items = [],
  reversed,
  title,
  video,
}: Properties) => (
  <BlockWithImageRichText
    content={content}
    title={title}
    backgroundColor={backgroundColor}
    image={image}
    reversed={reversed}
    video={video}>
    <FoldOutsWithLogos alwaysOpen={alwaysOpen} items={items} inverted={backgroundColor === 'blue'} />
  </BlockWithImageRichText>
);
