interface InputSizes {
  width: number;
  minWidth: number;
  ratio?: number;
}

export const getImageSizes = (inputSizes: InputSizes[], inputRatio?: 'portrait' | 'square' | 'landscape') => {
  let ratio: number;
  switch (inputRatio) {
    case 'portrait': {
      ratio = 3 / 4;
      break;
    }
    case 'landscape': {
      ratio = 16 / 10;
      break;
    }
    case 'square': {
      ratio = 1;
      break;
    }
    default: {
      ratio = 16 / 10;
    }
  }

  return inputSizes.map((item) => ({ ...item, ratio }));
};
