import React from 'react';
import { TextOverImageBlock } from '@klokgroep/sanity/src/corporateQueries/blocks/textOverImage';
import { ContentCardBoxedLarge } from '@klokgroep/shared-components/src/ContentCardBoxedLarge';
import { MaxWidth } from '@klokgroep/shared-components/src/MaxWidth';
import { BlockContainerWithBackground } from '@klokgroep/shared-components/src/BlockContainerWithBackground';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { PortableTextWithLinks } from '@klokgroep/shared-components/src/PortableTextWithLinks';
import { FlexRow } from '@klokgroep/shared-components/src/FlexRow';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';
import { renderButtonOrLink } from '@klokgroep/shared-components/utils/renderButtonOrLink';

export const BlockTextOverImage = ({ image, text, buttons, settings }: TextOverImageBlock) => {
  const { locale, theme } = useSiteInfo();

  return settings && settings?.isFullWidth ? (
    <ContentCardBoxedLarge image={image} isFullWidth>
      {text ? (
        <RichText>
          <PortableTextWithLinks content={text} />
        </RichText>
      ) : undefined}
      {buttons && buttons.length > 0 ? (
        <FlexRow noAutoFill wrap>
          {buttons.map((button, index) => (
            <React.Fragment key={index}>
              {renderButtonOrLink({ ...button, locale }, theme === 'vandeklok', 'orange')}
            </React.Fragment>
          ))}
        </FlexRow>
      ) : undefined}
    </ContentCardBoxedLarge>
  ) : (
    <BlockContainerWithBackground backgroundColor={settings?.backgroundColor}>
      <MaxWidth>
        <ContentCardBoxedLarge image={image}>
          {text ? (
            <RichText>
              <PortableTextWithLinks content={text} />
            </RichText>
          ) : undefined}
          {buttons && buttons.length > 0 ? (
            <FlexRow noAutoFill wrap>
              {buttons.map((button, index) => (
                <React.Fragment key={index}>
                  {renderButtonOrLink({ ...button, locale }, theme === 'vandeklok' || theme === 'novaform', 'orange')}
                </React.Fragment>
              ))}
            </FlexRow>
          ) : undefined}
        </ContentCardBoxedLarge>
      </MaxWidth>
    </BlockContainerWithBackground>
  );
};
